import React from "react";
import { FormattedMessage } from "react-intl";
import { IPackageHotelPublic, IRoomInput } from "../../../common/typings";
import { getBookingTotal } from "../../../firestore/firestoreService";
import { Button } from "../../atoms";
import { CartTotalBtnWrapper } from "./Cart.style";
interface ICartTotalBtnProps {
  submit: () => void;
  hotels: IPackageHotelPublic[];
  rooms: IRoomInput[];
  loading: boolean;
  luggages: {
    numberOf10KGLuggage: number;
    numberOf20KGLuggage: number;
    price10KGLuggage: number;
    price20KGLuggage: number;
  };
  discount: number;
  discountOffer?: number;
}
export const CartTotalBtn: React.FC<ICartTotalBtnProps> = ({
  submit,
  hotels,
  rooms,
  loading,
  luggages,
  discount,
  discountOffer,
}) => {
  const [finalPrice, setFinalPrice] = React.useState(0);
  const [finalPriceWithDiscount, setFinalPriceWithDiscount] = React.useState(0);
  React.useEffect(() => {
    const total = getBookingTotal(
      hotels || [],
      rooms || [],
      luggages,
      discount,
    );
    setFinalPrice(total);
    if (discountOffer) {
      const totalWithDiscount = getBookingTotal(
        hotels || [],
        rooms || [],
        luggages,
        discount,
        discountOffer,
      );
      setFinalPriceWithDiscount(totalWithDiscount);
    }
  }, [hotels, rooms, luggages, discountOffer]);
  return (
    <CartTotalBtnWrapper>
      <div className="cart-total-total">
        <span>
          {" "}
          <FormattedMessage
            description="Total"
            defaultMessage="Total"
            id="XmkR3j"
          />
        </span>
        {discountOffer ? (
          <span className="cart-total-discount">€{finalPrice}</span>
        ) : (
          <span className="cart-total-total-price">€{finalPrice}</span>
        )}
      </div>

      {discountOffer && (
        <div className="cart-total-discount-text">
          Προσφορά Μόνο για τη Black Friday! - 10%{" "}
          <span className="cart-total-total-price" style={{ color: "#fff" }}>
            €{finalPriceWithDiscount}
          </span>
        </div>
      )}
      <div style={{ padding: "10px 20px 20px" }}>
        <Button className="cart-total-btn" onClick={submit} loading={loading}>
          {" "}
          <FormattedMessage
            description="Book Now"
            defaultMessage="Book Now"
            id="1ZQWdS"
          />
        </Button>
      </div>
    </CartTotalBtnWrapper>
  );
};
