/* eslint-disable no-console */
import moment from "moment";
import { find } from "lodash";
import {
  IBooking,
  IBookingAgent,
  IBookingInput,
  IPackageHotelPublic,
  IRoomInput,
  ILuggage,
} from "../../common/typings";
import firebase from "../../config/firebase";
import { getRoomCost } from "../../common/util/util";
import { db } from "./firestoreService";

const COLLECTION_BOOKINGS = "bookings";
const COLLECTION_PACKAGES = "packages";

export function listenToBookingFromFirestore(id: string) {
  return db.collection(COLLECTION_BOOKINGS).doc(id);
}

export async function addBookingToFirestore(doc: IBookingInput) {
  try {
    const currentBookings = await db
      .collection(COLLECTION_BOOKINGS)
      .orderBy("bookingDate", "desc")
      .limit(1)
      .get();
    const booking = currentBookings.docs[0]?.data();
    // const total = getBookingTotal(
    //   doc?.packageHotels || [],
    //   doc?.rooms || [],
    //   doc?.luggages
    // );
    const total = await getBookingTotalFirebase(
      doc.travelPackageId,
      doc?.packageHotels || [],
      doc?.rooms || [],
      doc?.luggages,
      doc?.discount,
      doc?.discountOffer,
    );
    return db.collection(COLLECTION_BOOKINGS).add({
      ...doc,
      bookingDate: firebase.firestore.FieldValue.serverTimestamp(),
      status: "NEW",
      paymentStatus: "PENDING",
      comment: "",
      bookingId: booking ? Number(booking.bookingId) + 1 : 1,
      total,
      flights:
        doc.flights?.map((flight) => ({
          ...flight,
          departureDate: moment(flight.departureDate).toDate(),
          arrivalDate: moment(flight.arrivalDate).toDate(),
        })) || [],
      // createBy: user,
    });
  } catch (err) {
    throw err;
  }
}

export function updateBookingInFirestore(docId: string, doc: IBooking) {
  console.log(doc);
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({
      ...doc,
      flights:
        doc.flights?.map((flight) => ({
          ...flight,
          departureDate: moment(flight.departureDate).toDate(),
          arrivalDate: moment(flight.arrivalDate).toDate(),
        })) || [],
      passengers: doc.passengers.map((passenger) => ({
        ...passenger,
        dateOfBirth: moment(passenger.dateOfBirth).toDate(),
      })),
    });
}

export function updateBookingAgentInFirestore(
  docId: string,
  doc: IBookingAgent,
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({
      ...doc,
    });
}

export async function deleteBookingInFirestore(docId: string) {
  return db.collection(COLLECTION_BOOKINGS).doc(docId).delete();
}

export function activeToggleBookingInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_BOOKINGS).doc(docId).update({
    status,
  });
}

export function getBookingTotal(
  packageHotels: IPackageHotelPublic[],
  rooms: IRoomInput[],
  luggage: ILuggage,
  discount: number,
  discountOffer?: number,
) {
  const packageHotelsPrice = packageHotels.reduce(
    (previousHotel, currentHotel) =>
      Number(previousHotel) +
      Number(
        rooms?.reduce(
          (previousRoom, currentPrice) =>
            Number(previousRoom) +
            getRoomCost(currentPrice, currentHotel, discount),
          0,
        ),
      ),
    0,
  );
  const luggagesPrice = luggage
    ? luggage.numberOf10KGLuggage * luggage.price10KGLuggage +
      luggage.numberOf20KGLuggage * luggage?.price20KGLuggage
    : 0;
  const total = packageHotelsPrice + luggagesPrice;

  const packageHotelsPriceWithDiscount = discountOffer
    ? Math.round(total - total * discountOffer)
    : total;
  return packageHotelsPriceWithDiscount;
}

export async function getBookingTotalFirebase(
  packageId: string,
  packageHotels: IPackageHotelPublic[],
  rooms: IRoomInput[],
  luggage: ILuggage,
  discount: number,
  discountOffer?: number,
) {
  try {
    const packageFromFirebase = await db
      .collection(COLLECTION_PACKAGES)
      .doc(packageId)
      .get();
    const selectedPackage = packageFromFirebase.data();
    if (!selectedPackage) {
      throw new Error();
    }
    const packageHotelsPrice = packageHotels.reduce(
      (previousHotel, currentHotel) =>
        Number(previousHotel) +
        Number(
          rooms?.reduce((previousRoom, currentPrice) => {
            const selectedHotel = find(selectedPackage?.hotels || [], [
              "id",
              currentHotel.id,
            ]);
            return (
              Number(previousRoom) +
              getRoomCost(currentPrice, selectedHotel, discount)
            );
          }, 0),
        ),
      0,
    );
    const luggagesPrice = luggage
      ? luggage.numberOf10KGLuggage *
          Number(selectedPackage?.price10KGLuggage || 0) +
        luggage.numberOf20KGLuggage *
          Number(selectedPackage?.price20KGLuggage || 0)
      : 0;
    const total = packageHotelsPrice + luggagesPrice;
    const packageHotelsPriceWithDiscount = discountOffer
      ? Math.round(total - total * discountOffer)
      : total;
    return packageHotelsPriceWithDiscount;
  } catch (err) {
    throw err;
  }
}
