import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../atoms";
import { PackageActionWrapper } from "./PackageAction.style";

export interface IPackageActionProps {
  price: number;
  discount: number;
  currency: string;
  onSelect: () => void;
  discountOffer?: number;
}
export const PackageAction: React.FC<IPackageActionProps> = ({
  price,
  currency,
  discount,
  onSelect,
  discountOffer,
}) => {
  const currentDiscount: number = Math.ceil((+price * +discount) / 100);
  const currentPrice = +price - +currentDiscount;
  return (
    <PackageActionWrapper>
      <div className="package-action">
        <span className="package-action-text">
          <FormattedMessage
            description="From"
            defaultMessage="From"
            id="8MMe7B"
          />
        </span>
        <div className="package-action-price-box">
          <span className="package-action-price">
            {currency}
            {currentPrice}
          </span>
          <span className="package-action-text">
            <FormattedMessage
              description="Per Person"
              defaultMessage="Per Person"
              id="uw9sqa"
            />{" "}
          </span>
        </div>
      </div>
      <Button className="package-action-btn" onClick={onSelect}>
        <FormattedMessage
          description="More Details"
          defaultMessage="More Details"
          id="Hn4KXy"
        />
      </Button>
      {discountOffer && (
        <div className="package-action-discount-text">
          Black Friday τιμή από{" "}
          <span>
            {currency} {Math.round(currentPrice - currentPrice * discountOffer)}{" "}
          </span>
        </div>
      )}
      {/* <div>
        <Space className="package-action-space">
          <ShareAltOutlined />
          <MailOutlined />
          <PhoneOutlined />
        </Space>
      </div> */}
    </PackageActionWrapper>
  );
};
